<template>
	<w-dialog
		v-model="showDialog"
		persistent
		:max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'"
		no-padding
		:title="isRenaming ? $t('documents.rename', { type: $t('documents.types.folder') }) : $t('documents.create_folder')"
		@close="closeFolderCreation()"
		@ready="onDialogReady"
	>
		<w-layout column>
			<w-layout v-if="loadingReplicableFolders" align-center justify-center pa-5>
				<v-progress-circular indeterminate color="primary" />
			</w-layout>
			<template v-else>
				<w-flex v-if="hasReplicableFolders && !isRenaming" shrink>
					<w-divider />
					<v-tabs v-model="activeTab" show-arrows grow @change="resetData">
						<v-tabs-slider color="primary"></v-tabs-slider>
						<v-tab ripple active-class="primary--text">{{ $t('documents.create_folder') }}</v-tab>
						<v-tab v-for="(replicableFolder, index) in replicableFolders" :key="index" ripple active-class="primary--text">{{ replicableFolder.text }}</v-tab>
					</v-tabs>
					<w-divider />
				</w-flex>
				<w-flex>
					<BasicFolderCreator
						v-if="isBasicFolderCreator"
						ref="folderCreator"
						v-model="data.name"
						:rules="rules"
						@save="actionOnFolder()"
					/>
					<ReplicableFolderCreator
						v-if="!isBasicFolderCreator && replicableFolderSelected"
						ref="folderCreator"
						v-model="data"
						:rules="rules"
						:selected-folder="replicableFolderSelected"
						@save="actionOnFolder()"
					/>
				</w-flex>
			</template>
		</w-layout>
		<template v-slot:actions>
			<w-layout align-center row justify-end wrap>
				<w-btn 
					:disabled="!isValid || loadingReplicableFolders" 
					flat :loading="loading" 
					@click="actionOnFolder">{{ $t('actions.save') }}
				</w-btn>
			</w-layout>
		</template>
	</w-dialog>	
</template>

<script>
import Vue from 'vue'

import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'
import NodeCreationMixin from '@/mixins/Documents/NodeCreationMixin'

export default {
	name: 'FolderCreator',
	components: {
		BasicFolderCreator: () => ({
			component: import('@/components/Documents/Actions/Add/BasicFolderCreator')
		}),
		ReplicableFolderCreator: () => ({
			component: import('@/components/Documents/Actions/Add/ReplicableFolderCreator')
		})
	},
	mixins: [DocumentsManagerModuleGuard, NodeCreationMixin],
	props: {
		value: {
			type: Object,
			required: false,
			default: null,
			validator: function (value) {
				return value ? value.is_folder : true
			}
		},
		isRenaming: {
			type: Boolean,
			required: false,
			default: false
		},
	},
	data: function () {
		return {
			activeTab: this.isRenaming ? 1 : 0,
			data: {},
			showDialog: false,
			loading: false,
			loadingReplicableFolders: false,
			replicableFolders: [],
			rules: {
				validName: value => {
					const regexp0 = new RegExp(/^[ \t]*$/, 'i')
					const regexp1 = new RegExp(/[?/^\\|:<>*"]/, 'i')
					const regexp2 = new RegExp(/^[\s]+$|^$/, 'i')
					let name = value
					if (this.replicableFolderSelected) {
						name =  this.replicableFolderSelected.template.replace('%' + this.replicableFolderSelected.flag + '%', value)
					}
					let result = true
					if (regexp0.test(name)) {
						result = this.$t('documents.bad_item_empty_name')
					} else if (regexp1.test(name)) {
						result = this.$t('documents.bad_item_name')
					} else if (regexp2.test(name)) {
						result = false
					} else if (this.___getExistingNodeWithSameName(this.parentFolder, name)) {
						const existingNode = this.___getExistingNodeWithSameName(this.parentFolder, name)
						result = this.$t('documents.duplicate.node', {
							type: existingNode.is_folder ? this.$t('documents.types.folder') : this.$t('documents.types.file')
						})
					}
					return result
				}
			}
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		parentFolder: function () {
			return this.isRenaming ? this.model.parent : this.model
		},
		isValid: function () {
			let result = null
			if (!this.replicableFolderSelected) {
				result = this.data.name && typeof this.rules.validName(this.data.name) !== 'string' && this.data.name.length < 191
			} else {
				switch (this.replicableFolderSelected.type) {
					case 'boolean':
						result = true
						break
					case 'year':
						result = this.data.year && this.data.year.toString().length === 4
						break
					case 'semester':
						result = this.data.semester && this.data.semester.toString().length === 4
						break
					case 'quarter':
						result = this.data.semester && this.data.semester.toString().length === 4
						break
					case 'month':
						result = this.data.month && this.data.month.toString().length === 4
						break
					case 'week':
						result = this.data.week && this.data.week.toString().length === 4
						break
					case 'day':
						result = this.data.day && this.data.day.toString().length === 4
						break
					default:
						result =
							this.data[this.replicableFolderSelected.flag] &&
							typeof this.rules.validName(this.data[this.replicableFolderSelected.flag]) !== 'string' &&
							this.data[this.replicableFolderSelected.flag].length < 191
						break
				}
				result = result && !this.isSameName
			}
			return result
		},
		replicableFolderSelected: function () {
			const replicableFolders = this.replicableFolders
			const activeTab = this.activeTab

			let result
			if (!isNaN(activeTab) && activeTab > 0 && replicableFolders.length > activeTab - 1) {
				result = replicableFolders[activeTab - 1]
				if (!this.isRenaming) {
					this.setDefaultData(result.type)
				}
			} else {
				result = null
			}
			return result
		},
		hasReplicableFolders: function () {
			return this.replicableFolders.length > 0
		},
		isBasicFolderCreator: function () {
			return this.activeTab === 0
		},
		isSameName: function() {
			return this.data[this.replicableFolderSelected.flag] == this.model.name
		}
	},
	watch: {
		showDialog(newValue) {
			if (newValue) {
				this.tryToFocusInput();
			}
		}
	},
	mounted: function () {
		this.showDialog = true
		if (this.model.catalog_tree_folder_id) {
			this.getReplicableFolders()
		}
	},
	destroyed: function () {
		this.closeFolderCreation()
	},
	methods: {
		closeFolderCreation: function () {
			this.$emit('close')
		},
		actionOnFolder: function () {
			if (!this.isValid || this.loading) {
				return
			}
			this.loading = true
			let action
			if (this.isRenaming) {
				action = this.renameFolder()
			} else {
				action = this.createFolder()
			}
			action.finally(() => {
				this.loading = false
				this.closeFolderCreation()
				this.showDialog = false
			})
		},
		createFolder: function () {
			let result
			if (this.replicableFolderSelected) {
				result = this.service.createFolderCatalogFolders(this.vendorId, this.model, this.replicableFolderSelected.catalog_tree_folder_id, this.data)
			} else {
				result = this.service.createFolder(this.vendorId, this.model, this.data)
			}
			return result
		},
		renameFolder: function() {
			const vendorId = this.vendorId ? this.vendorId : this.model.vendor_id
			const folderId = this.parentFolder ? this.parentFolder.id : this.model.folder_id
			const data = {'flags': this.data}
			return this.service.updateFolder(vendorId, folderId, this.model, null, data).then((newName) => {
				this.model.name = newName.name
				this.loading = false
				this.closeFolderCreation()
				this.showDialog = false
			})
		},
		getReplicableFolders: async function () {
			this.loadingReplicableFolders = true
			try {
				const folderId = this.isRenaming ? this.model.folder_id : this.model.id
				const replicableFolders = await this.service.getFolderCatalogFolders(this.vendorId, folderId)
				
				this.replicableFolders = replicableFolders.map(replicableFolder => {
					replicableFolder.text = this.$t(`documents.folder_creation.${replicableFolder.flag}.text`)
					replicableFolder.title = this.$t(`documents.folder_creation.${replicableFolder.flag}.title`) 
					return replicableFolder
				})

				if (this.isRenaming) {
					this.setData()
				}
			} catch {
				this.closeFolderCreation()
			} finally {
				this.loadingReplicableFolders = false
			}
		},
		resetData: function () {
			this.data = {}
		},
		setDefaultData: function (type) {
			switch (type) {
				case 'boolean':
					Vue.set(this.data, 'value', true)
					break
				case 'year':
					Vue.set(this.data, 'year', new Date().getFullYear())
					break
				case 'month':
					Vue.set(this.data, 'month', new Date().getMonth())
					break
				case 'week':
					Vue.set(this.data, 'week', new Date().getWeek())
					break
				case 'day':
					Vue.set(this.data, 'day', new Date().getDay())
					break
				default:
					break
			}
		},
		setData: function () {
			const type = this.replicableFolderSelected.type
			const template = this.replicableFolderSelected.template
			const templateParts = template.split('%')
			const name = this.model.name.replace(templateParts[0], '').replace(templateParts[2], '')
			switch (type) {
				case 'boolean':
					Vue.set(this.data, 'value', name)
					break
				case 'year':
					Vue.set(this.data, 'year', parseInt(name))
					break
				case 'month':
					Vue.set(this.data, 'month', name)
					break
				case 'week':
					Vue.set(this.data, 'week', name)
					break
				case 'day':
					Vue.set(this.data, 'day', name)
					break
				default:
					Vue.set(this.data, this.replicableFolderSelected.flag, name)
			}
		},
		onDialogReady: function() {
			// Keep this as a backup for cases where the dialog is immediately shown
			if (this.showDialog) {
				this.tryToFocusInput();
			}
		},
		tryToFocusInput: function() {
			this.$nextTick(() => {
				const creator = this.$refs.folderCreator;
				if (creator) {
					this.focusInput(creator);
				}
			});
		},
		focusInput: function(component) {
			// Wait for the next tick to ensure child components are mounted
			this.$nextTick(() => {
				if (component?.$refs?.nameInput) {
					const input = component.$refs.nameInput;
					if (input.focus) {
						input.focus();
					} else if (input.$el && input.$el.focus) {
						input.$el.focus();
					}
				}
			});
		}
	}
}
</script>
